import {
	ADMISSION,
	ClassTypes,
	CMR,
	CTC,
	DocumentStatuses,
	INVOICE,
	PASSPORT_INT,
	PASSPORT_RF,
} from './types'

export const CREATED = 'CREATED'
export const PAGINATION = 'PAGINATION'
export const PAGINATED = 'PAGINATED'
export const PAGINATION_FAILED = 'PAGINATION_FAILED'
export const CLASSIFICATION = 'CLASSIFICATION'
export const CLASSIFIED = 'CLASSIFIED'
export const CLASSIFICATION_FAILED = 'CLASSIFICATION_FAILED'
export const CLASSIFICATION_ALL_OTHERS = 'CLASSIFICATION_ALL_OTHERS'
export const RECOGNITION = 'RECOGNITION'
export const RECOGNIZED = 'RECOGNIZED'
export const RECOGNITION_FAILED = 'RECOGNITION_FAILED'
export const VERIFIED = 'VERIFIED'
export const REPORT_GENERATION = 'XLSX_GENERATION'
export const REPORT_GENERATED = 'XLSX_GENERATED'
export const REPORT_GENERATION_FAILED = 'XLSX_GENERATION_FAILED'

export const documentStatuses = {
	CREATED,
	PAGINATION,
	PAGINATED,
	PAGINATION_FAILED,
	CLASSIFICATION,
	CLASSIFICATION_FAILED,
	CLASSIFICATION_ALL_OTHERS,
	CLASSIFIED,
	RECOGNITION,
	RECOGNIZED,
	RECOGNITION_FAILED,
	VERIFIED,
	REPORT_GENERATION,
	REPORT_GENERATED,
	REPORT_GENERATION_FAILED,
} as const

export const documentStatusLabels: Record<DocumentStatuses, string> = {
	[CREATED]: 'Создан',
	[PAGINATION]: 'Разбивка на страницы',
	[PAGINATED]: 'Разбит на страницы',
	[PAGINATION_FAILED]: 'Разбивка на страницы: Ошибка',
	[CLASSIFICATION]: 'Классификация',
	[CLASSIFIED]: 'Классифицировано',
	[CLASSIFICATION_FAILED]: 'Классификация: Ошибка',
	[CLASSIFICATION_ALL_OTHERS]: 'Не обработан',
	[RECOGNITION]: 'Распознавание',
	[RECOGNIZED]: 'Распознано',
	[RECOGNITION_FAILED]: 'Распознавание: Ошибка',
	[VERIFIED]: 'Проверено',
	[REPORT_GENERATION]: 'Генерация результата',
	[REPORT_GENERATED]: 'Результат сгенерирован',
	[REPORT_GENERATION_FAILED]: 'Генерация результата: Ошибка',
} as const

export const FAILED_STATUSES = new Set([
	PAGINATION_FAILED,
	CLASSIFICATION_FAILED,
	CLASSIFICATION_ALL_OTHERS,
	RECOGNITION_FAILED,
	REPORT_GENERATION_FAILED,
])

export const PAGE_ITEMS = 'items'
export const PAGE_ATTRIBUTES = 'attributes'

// TODO узнать сколько лимит
export const UPLOADED_FILE_MAX_SIZE_MB = 300

export const INTERVAL_GET_PACKAGE_STATUS = 5000

export const CLASS_TYPES_LABELS: { [key in ClassTypes]: string } = Object.freeze({
	[ClassTypes.CMR]: 'CMR',
	[ClassTypes.INVOICE]: 'Инвойс',
	[ClassTypes.PASSPORT_RF]: 'Паспорт РФ',
	[ClassTypes.PASSPORT_INT]: 'Паспорт Иностранный',
	[ClassTypes.CTC]: 'CTC',
	[ClassTypes.ADMISSION]: 'Допуск к международным перевозкам',
	[ClassTypes.OTHER]: 'Прочее',
	[ClassTypes.COMPLEX_DOC]: 'Составной документ',
})

export const CMR_LABELS: { [key in CMR]: string } = Object.freeze({
	[CMR.Number]: 'Номер CMR',
	[CMR.Date]: 'Дата CMR',

	[CMR.SenderCompany]: 'Отправитель',
	[CMR.SenderCity]: 'Город отправителя',
	[CMR.SenderStreet]: 'Улица отправителя',
	[CMR.SenderPost]: 'Индекс отправителя',
	[CMR.SenderCountry]: 'Страна отправителя',
	[CMR.SenderCountryCode]: 'Код страны отправителя',

	[CMR.ConsigneeCompany]: 'Получатель',
	[CMR.ConsigneeCity]: 'Город получателя',
	[CMR.ConsigneeStreet]: 'Улица получателя',
	[CMR.ConsigneePost]: 'Индекс получателя',
	[CMR.ConsigneeCountry]: 'Страна получателя',
	[CMR.ConsigneeCountryCode]: 'Код страны получателя',
	[CMR.ConsigneeINN]: 'ИНН получателя',
	[CMR.ConsigneeOGRN]: 'ОГРН получателя',
	[CMR.ConsigneeKPP]: 'КПП получателя',

	[CMR.VehicleNo]: 'Номер тягача и прицепа',
	[CMR.VehicleType]: 'Код ТС (30/31/90)',
	[CMR.VehicleRegistrationCountry]: 'Страна регистрации ТС',

	[CMR.CarrierCompany]: 'Перевозчик',
	[CMR.CarrierCity]: 'Город перевозчика',
	[CMR.CarrierStreet]: 'Улица перевозчика',
	[CMR.CarrierPost]: 'Индекс перевозчика',
	[CMR.CarrierCountry]: 'Страна перевозчика',
	[CMR.CarrierCountryCode]: 'Код страны перевозчика',
	[CMR.CarrierINN]: 'ИНН перевозчика',
	[CMR.CarrierOGRN]: 'ОГРН перевозчика',
	[CMR.CarrierKPP]: 'КПП перевозчика',

	[CMR.DestinationCountry]: 'Страна назначения',
	[CMR.LoadingCountry]: 'Страна отправления',

	[CMR.PackageQuantity]: 'Количество мест',
	[CMR.PackingType]: 'Вид упаковки',
	[CMR.GrossWeight]: 'Вес брутто',
	[CMR.CodeTN]: 'Код ТНВЭД',
	[CMR.ProductDescription]: 'Наименование товара',

	[CMR.CustomCode]: 'Код таможни',
	[CMR.CustomName]: 'Название таможни',

	[CMR.Documents]: 'Прилагаемые документы',
	[CMR.DeliveryTermsCode]: 'Условия поставки (Код)',
	[CMR.DeliveryTermsCity]: 'Условия поставки (Город)',
})
export const INVOICE_LABELS: { [key in INVOICE]: string } = Object.freeze({
	[INVOICE.Number]: 'Номер',
	[INVOICE.Date]: 'Дата',
	[INVOICE.Currency]: 'Валюта',
	[INVOICE.Sender]: 'Отправитель',
	[INVOICE.Consignee]: 'Получатель',
	[INVOICE.TotalAmount]: 'Общая сумма',
	[INVOICE.TotalTax]: 'Общая сумма налога',
	[INVOICE.productCode]: 'Код международный',
	[INVOICE.productCodeTn]: 'Код ТН ВЭД',
	[INVOICE.description]: 'Описание товара',
	[INVOICE.quantity]: 'Количество',
	[INVOICE.grossWeight]: 'Вес брутто',
	[INVOICE.netWeight]: 'Вес нет',
	[INVOICE.taxRate]: 'Налоговая ставка',
	[INVOICE.tax]: 'Сумма налога',
	[INVOICE.unitPrice]: 'Цена за единицу',
	[INVOICE.amountLine]: 'Сумма всего',
	[INVOICE.unitType]: 'Единица измерения',
})

export const PASSPORT_RF_LABELS: { [key in PASSPORT_RF]: string } = Object.freeze({
	[PASSPORT_RF.SERIES]: 'Серия',
	[PASSPORT_RF.NUMBER]: 'Номер',
	[PASSPORT_RF.AUTHORITY_NAME]: 'Место выдачи',
	[PASSPORT_RF.ISSUE_DATE]: 'Дата выдачи',
	[PASSPORT_RF.AUTHORITY_CODE]: 'Код подразделения',
	[PASSPORT_RF.LAST_NAME]: 'Фамилия',
	[PASSPORT_RF.FIRST_NAME]: 'Имя',
	[PASSPORT_RF.MIDDLE_NAME]: 'Отчество',
	[PASSPORT_RF.GENDER]: 'Пол',
	[PASSPORT_RF.BIRTHPLACE]: 'Место рождения',
	[PASSPORT_RF.BIRTHDAY]: 'Дата рождения',
})
export const PASSPORT_INT_LABELS: { [key in PASSPORT_INT]: string } = Object.freeze({
	[PASSPORT_INT.Name]: 'Имя',
	[PASSPORT_INT.Surname]: 'Фамилия',
	[PASSPORT_INT.Nationality]: 'Гражданство',
	[PASSPORT_INT.Number]: 'Номер',
	[PASSPORT_INT.DateOfBirth]: 'Дата рождения',
	[PASSPORT_INT.DateOfIssue]: 'Дата выдачи',
	[PASSPORT_INT.DateOfExpiry]: 'Срок действия',
	[PASSPORT_INT.PlaceOfBirth]: 'Место рождения',
	[PASSPORT_INT.PNumber]: 'Номер паспорта',
	[PASSPORT_INT.PSeries]: 'Серия паспорта',
})
export const CTC_LABELS: { [key in CTC]: string } = Object.freeze({
	[CTC.Number]: 'Номер',
	[CTC.Reg_number_trailer]: 'Регистрационный номер прицепа',
	[CTC.Reg_number_car]: 'Регистрационный номер машины',
	[CTC.VIN]: 'VIN',
	[CTC.Weight]: 'Вес',
	[CTC.Mass]: 'Масса',
	[CTC.Year]: 'Год',
	[CTC.Engine_power]: 'Мощность двигателя',
})

export const ADMISSION_LABELS: { [key in ADMISSION]: string } = Object.freeze({
	[ADMISSION.Number]: 'Номер',
	[ADMISSION.Issue_date]: 'Дата выдачи',
	[ADMISSION.Valid_date]: 'Срок действия',
	[ADMISSION.Reg_number_trailer]: 'Регистрационный номер прицепа',
	[ADMISSION.Reg_number_car]: 'Регистрационный номер машины',
})
